require('./bootstrap');

import Alpine from 'alpinejs';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import ApexCharts from 'apexcharts';

window.Alpine = Alpine;

Alpine.start();

tippy('[data-tippy-content]');

// ----------------------------------------
// Charting, very simple solution
// ----------------------------------------
let apexCharts = [];

const allCharts = document.querySelectorAll(".chart");

if (allCharts) {

    for (let i = 0; i < allCharts.length; i++) {

        let chartRoute = allCharts[i].dataset.route;
        let chartType = allCharts[i].dataset.type;
        let chartOptions = JSON.parse(allCharts[i].dataset.options);
        let chartFormatter = allCharts[i].dataset.formatter;
        let chartFullDataBtn = false; //todo

        if (chartType === 'donut') {

            chartOptions.legend.formatter = function (seriesName, opts) {
                return ["<span style='width: 50%; display: inline-block'>" + seriesName + "</span>",
                    "<strong style='width: 15%; display: inline-block'>" + opts.w.globals.series[opts.seriesIndex] + "</strong>",
                    "<strong style='width: 15%;color:#2036FE; display: inline-block'>" + Math.floor(opts.w.globals.seriesPercent[opts.seriesIndex]) + "% </strong>",
                ]
            }

            if (chartFormatter === 'money') {

                chartOptions.legend.formatter = function (seriesName, opts) {
                    return ["<span style='width: 50%; display: inline-block'>" + seriesName + "</span>",
                        "<strong style='text-align:right;width: 30%; display: inline-block'>" + (new Intl.NumberFormat('lv-LV', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(opts.w.globals.series[opts.seriesIndex])) + "</strong>",
                    ]
                }
            }

        }

        if (chartType === 'horizontal-bar' && chartFormatter === 'money') {
            chartOptions.xaxis.labels.formatter = function (value) {
                return value + " €";
            }
        }

        if (chartType === 'vertical-bar' && chartFormatter === 'money') {
            chartOptions.yaxis.labels.formatter = function (value) {
                return value + " €";
            }
        }

        if (chartFullDataBtn !== false && chartFullDataBtn !== "") {
         //   console.log(chartFullDataBtn);
//console.log(chartOptions.chart.toolbar.tools.customIcons[0]);
//             chartOptions.chart.toolbar.tools.push({
//                  customIcons: []
//
//             })
            // chartOptions.chart.toolbar.tools.customIcons[0].click = function() {
            //         console.log("clicked custom-icon")
            // }

          //  console.log(chartOptions.chart.toolbar.tools.customIcons[0]);
            // chartOptions.chart.toolbar.tools.customIcons[0].push ([
            //     click: function (chart, options, e) {
            //     console.log("clicked custom-icon")
            // }]
            // )
        }

        apexCharts[i] = new ApexCharts(allCharts[i], chartOptions);
        apexCharts[i].render();

        loadChartData(chartRoute, apexCharts[i]);
    }

    function loadChartData(chartRoute, chart) {

        window.axios({
            method: 'GET',
            url: chartRoute,
        }).then(function (response) {
            let loadMoreBtn = chart.el.nextElementSibling;

            // norāda iespēju ielādēt papildus datus, ja pirmajā reizē total dati ir vairāk par atrāditajiem datiem
            if (response.data.totalY > response.data.outputY) {
                if (typeof loadMoreBtn.dataset.route !== "undefined") {
                    loadMoreBtn.addEventListener("click", function () {
                        loadChartData(loadMoreBtn.dataset.route, chart);
                        loadMoreBtn.remove();
                    }, false);
                }
            } else {
                loadMoreBtn.remove();
            }

            chart.updateOptions(response.data);
        })
    }
}
